body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
  font-family: Cardo;
  font-weight: 400;
  src: url(./fonts/Cardo/Cardo-Regular.ttf) format("truetype");
}

@font-face {
  font-family: Cardo;
  font-weight: 700;
  src: url(./fonts/Cardo/Cardo-Bold.ttf) format("truetype");
}

@font-face {
  font-family: Faro;
  font-weight: 400;
  src: url(./fonts/Faro/Faro-RegularLucky.otf) format("opentype");
}

@font-face {
  font-family: Faro;
  font-weight: 700;
  src: url(./fonts/Faro/Faro-BoldLucky.otf) format("opentype");
}

@font-face {
  font-family: Paradizo;
  font-weight: 400;
  src: url(./fonts/Paradizo/Paradizo-Regular.otf) format("opentype");
}
